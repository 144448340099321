import moment from "moment";
import { formatRupiah } from "../../utils/moneyFormat";
import { contractFormat } from "../../utils/helpers";
import { DataProductProfile } from "../../data/DataProductProfile";
moment.locale("id");

type ContractCardProps = {
  contractNo?: string;
  contractStatus?: string;
  creditInfo?: any;
  dueDate?: string;
  isCarousel?: boolean;
  contractCategory?: string;
  requestId?: string;
  isMistake?: boolean;
};
export const ContractCard = ({
  contractNo,
  contractStatus,
  creditInfo,
  dueDate,
  isCarousel,
  contractCategory,
  requestId,
  isMistake = false
}: ContractCardProps) => {
  const date = !dueDate
    ? "-"
    : moment(dueDate, ["DD-MM-YYYY"]).format("DD-MM-YYYY");
  const installment = formatRupiah(creditInfo);

  return (
    <a
      href={
        requestId && !isMistake
          ? `${process.env.clientZoneAuth}${requestId}`
          : `${process.env.credstoreAuth}${contractNo}`
      }
    >
      <div className="contract-carousel-wrapper">
        <div className="contract-card">
          {isCarousel && (
            <div className="contract-icon">
              <img
                src={
                  DataProductProfile[contractCategory]?.icon
                    ? DataProductProfile[contractCategory].icon
                    : DataProductProfile["PP_MPF"].icon
                }
              ></img>
            </div>
          )}
          <div className="contract-info">
            {isCarousel && (
              <div className="contract-info-item top">
                <div>No.{contractFormat(contractNo)}</div>
                <div className="contract-status">{contractStatus}</div>
              </div>
            )}
            {!isCarousel && (
              <div className="contract-info-item top">
                <div className="contract-items title">
                  <img
                    className="contract-items-icon"
                    src={DataProductProfile[contractCategory].icon}
                  ></img>
                  <h4>{DataProductProfile[contractCategory].name}</h4>
                </div>
                <div className="contract-items">
                  <p>No. Kontrak:</p>
                  <div>{contractFormat(contractNo)}</div>
                </div>
              </div>
            )}
            {!isCarousel && <hr className="solid" />}
            {isCarousel && (
              <div className="contract-info-item">
                <div className="contract-items">
                  <p>Jumlah Cicilan</p>
                  <div>{installment}</div>
                </div>
                {date && (
                  <div className="contract-items">
                    <p>Jatuh Tempo</p>
                    <div>{date}</div>
                  </div>
                )}
              </div>
            )}
            {!isCarousel && (
              <div className="contract-info-item bot">
                <div className="contract-items installment">
                  <p>Cicilan:</p>
                  <div>{installment}</div>
                </div>
                <div className="contract-items">
                  <p>Jatuh Tempo:</p>
                  <div className="text--red">{date}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .contract-carousel-wrapper {
          margin: 16px 0;
          display: flex;
        }
        .contract-carousel-wrapper::before {
          width: 10px;
          height: 25px;
          content: "";
        }
        .contract-carousel-wrapper::after {
          width: 10px;
          height: 25px;
          content: "";
        }
        .contract-card {
          width: 405px;
          height: ${isCarousel ? "130px" : "auto"};
          box-shadow: 0 2px 4px 0 #4b4a4a;
          border-radius: 10px;
          border-left: ${isCarousel ? "10px solid var(--color-green)" : "none"};
          display: flex;
        }
        .contract-icon {
          margin: 16px;
        }
        .contract-info {
          display: flex;
          flex-direction: column;
          margin: ${isCarousel ? "16px 16px 0 0" : "none"};
          width: 100%;
        }
        .contract-info-item {
          display: flex;
          justify-content: space-between;
          align-items: ${isCarousel ? "" : "center"};
        }
        .contract-items {
          display: flex;
          flex-direction: column;
          margin-top: 12px;
        }
        .contract-items p {
          margin-top: 0px;
          margin-bottom: 6px;
          font-size: ${isCarousel ? "14px" : "12px"};
        }
        .contract-status {
          color: white;
          background-color: var(--color-green);
          padding: 4px 20px;
          border-radius: 12px;
        }
        .contract-items div {
          font-weight: bold;
          font-size: ${isCarousel ? "14px" : "16px"};
        }
        .top {
          display: flex;
          border-bottom: ${isCarousel ? "solid 1px #d8d8d8" : "none"};
          align-items: ${isCarousel ? "flex-end" : "none"};
          padding-bottom: ${isCarousel ? "8px" : "none"};
          margin: ${isCarousel ? "none" : "21px 21px 12px 16px"};
        }
        .bot {
          justify-content: unset;
          margin: 12px 21px 21px 30px;
        }
        .installment {
          width: 50%;
        }
        .title {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: auto;
        }
        .title h4 {
          width: 100%;
          font-size: 18px;
        }
        hr.solid {
          display: flex;
          align-self: flex-end;
          border-top: 1px solid var(--color-gray-lightest);
          width: 83%;
        }

        .text {
          padding: 10px 0 0;
          color: black;
          font-weight: 700;
          letter-spacing: 1px;
        }

        @media only screen and (max-width: 480px) {
          .contract-card {
            width: 350px;
          }
          hr.solid {
            width: 77%;
          }
          .title h4 {
            font-size: 16px;
          }
        }
        @media only screen and (max-width: 400px) {
          .contract-card {
            width: 300px;
          }
          .contract-icon {
            margin: 16px 0px;
          }
        }
      `}</style>
    </a>
  );
};
