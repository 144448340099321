type NoContractProps = {
  outerWidth: number;
};

const NoContract = ({ outerWidth }: NoContractProps) => {
  return (
    <div id="no-contract-outer" className="no-contract-outer">
      <div className="no-contract-wrapper">
        <div className="no-contract-container">
          <img src="/static/images/svg/ic_utility_history_medium_grey@SVG.svg"></img>
          <p>
            Kamu tidak memiliki kontrak aktif, kontrak yang kamu buat akan
            muncul di sini​
          </p>
        </div>
      </div>
      <style jsx>{`
        .no-contract-outer {
          padding: ${outerWidth > 500 ? "116px 16px" : "32px 16px"};
          display: flex;
          justify-content: center;
        }
        .no-contract-wrapper {
          background-color: var(--color-white-soft);
          border-radius: 5px;
          max-width: 900px;
          width: 100%;
        }

        .no-contract-container {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .no-contract-container p {
          font-size: ${outerWidth > 500 ? "16px" : "12px"};
          margin: 0 8px;
        }
        .no-contract-container img {
          margin: 16px;
        }
        @media only screen and (max-width: 700px) {
          .no-contract-outer {
            padding: 32px 16px;
          }
          .no-contract-container p {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default NoContract;
